import React from "react"
import PropTypes from "prop-types"
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'

class MenteeGroupList extends React.Component {
  render() {
    const data = this.props.mentee_groups

    const columns = [
      {
        Header: 'ID',
        accessor: 'application_id',
        filterable: true,
        width: 75
      },
      {
        Header: 'Status',
        accessor: 'status',
        filterMethod: (filter, row) => {
          if (filter.value == "all") {
            return true;
          }

          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange}) => (
          <select onChange={event => onChange(event.target.value)} style={{width: "100%"}} value={filter ? filter.value : "all"}>
            <option value="all">Show All</option>
            <option value="Not eligible">Not Eligible</option>
            <option value="Closed">Closed</option>
            <option value="Waiting">Waiting</option>
            <option value="Pending payment">Pending Payment</option>
            <option value="Needs homework">Needs Homework</option>
            <option value="Pending homework">Pending Homework</option>
            <option value="Pending mentor">Pending Mentor</option>
            <option value="Mentor declined">Mentor Declined</option>
            <option value="Current">Current</option>
            <option value="Complete">Complete</option>
          </select>
        )
      },
      {
        Header: 'Application',
        accessor: 'application_date',
        filterable: true,
        width: 100
      },
      {
        Header: 'Payment',
        accessor: 'payment_date',
        filterable: true,
        width: 100
      },
      {
        Header: 'Engaged Couple',
        accessor: 'name',
        filterable: true,
        width: 220
      },
      {
        Header: 'Couple Church',
        accessor: 'church',
        filterable: true
      },
      {
        Header: 'Mentors',
        accessor: 'mentors',
        filterable: true
      },
      {
        Header: 'Split',
        accessor: 'split',
        filterable: true,
        width: 115
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        filterable: true,
        width: 90
      },
      {
        Header: 'Discount',
        accessor: 'discount',
        filterable: true,
        width: 90
      },
      {
        Header: '',
        accessor: 'links',
        Cell: ({value}) => (
          <div>
            <a href={value["edit"]} className='action-button edit'><i className="fi-pencil tooltips" data-original-title="Edit Entry" title="Edit Entry"></i></a>
          </div>
        ),
        filterable: false,
        width: 50
      }
    ]

    return (
      <ReactTable 
        data={data} 
        columns={columns}
        className="-striped -highlight"
        filterable
        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
      />
    );
  }
}

MenteeGroupList.propTypes = {
  mentee_groups: PropTypes.array
};

export default MenteeGroupList