import React from "react"
import PropTypes from "prop-types"
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'

class MentorGroupList extends React.Component {
  render() {
    const data = this.props.mentor_groups

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        filterable: true,
        width: 350
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 130,
        filterMethod: (filter, row) => {
          if (filter.value == "all") {
            return true;
          }

          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange}) => (
          <select onChange={event => onChange(event.target.value)} style={{width: "100%"}} value={filter ? filter.value : "all"}>
            <option value="all">Show All</option>
            <option value="Assigned">Assigned</option>
            <option value="Available">Available</option>
            <option value="Unavailable">Unavailable</option>
            <option value="Inactive">Inactive</option>
          </select>
        )
      },
      {
        Header: 'Church',
        accessor: 'church',
        filterable: true
      },
      {
        Header: 'Apprentices?',
        accessor: 'apprentice',
        filterable: true,
        width: 130
      },
      {
        Header: 'Couple',
        accessor: 'mentees',
        filterable: true,
        width: 350
      },
      {
        Header: '',
        accessor: 'links',
        Cell: ({value}) => (
          <div>
            <a href={value["edit"]} className='action-button edit'><i className="fi-pencil tooltips" data-original-title="Edit Entry" title="Edit Entry"></i></a>
          </div>
        ),
        filterable: false,
        width: 90
      }
    ]

    return (
      <ReactTable
        data={data}
        columns={columns}
        className="-striped -highlight"
        filterable
        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
      />
    );
  }
}

MentorGroupList.propTypes = {
  mentor_groups: PropTypes.array
};

export default MentorGroupList
