import React from "react"
import PropTypes from "prop-types"
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'

class MenteeGroupList extends React.Component {
  render() {
    const data = this.props.mentee_groups

    const columns = [
      {
        Header: 'ID',
        accessor: 'application_id',
        filterable: true,
        width: 60
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 100,
      },
      {
        Header: 'Application',
        accessor: 'application_date',
        filterable: true,
        width: 100
      },
      {
        Header: 'Name',
        accessor: 'name',
        filterable: true,
        width: 200
      },
      {
        Header: 'Church',
        accessor: 'church',
        filterable: true,
        width: 80
      },
      {
        Header: 'Wedding',
        accessor: 'wedding_date',
        filterable: true,
        width: 100
      },
      {
        Header: 'Officiant',
        accessor: 'officiant',
        filterable: true,
        width: 100
      },
      {
        Header: 'Payment',
        accessor: 'amount',
        filterable: true,
        width: 80
      },
      {
        Header: 'Mentors',
        accessor: 'mentors',
        filterable: true
      },
      {
        Header: 'Apprentices',
        accessor: 'apprentices',
        filterable: true,
      },
      {
        Header: '',
        accessor: 'links',
        Cell: ({value}) => (
          <div>
            <a href={value["edit"]} className='action-button edit'><i className="fi-pencil tooltips" data-original-title="Edit Entry" title="Edit Entry"></i></a>
            <a href={value["status"]} className='action-button change-status'><i className="fi-arrow-right tooltips" data-original-title="Change Status" title="Change Status"></i></a>
          </div>
        ),
        filterable: false,
        width: 90
      }
    ]

    return (
      <ReactTable 
        data={data} 
        columns={columns}
        className="-striped -highlight"
        filterable
        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
      />
    );
  }
}

MenteeGroupList.propTypes = {
  mentee_groups: PropTypes.array
};

export default MenteeGroupList